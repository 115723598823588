// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file announcement/v1/type.proto (package announcement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { RFC3339DateTime, UUID } from "../../shared/type_pb.js";

/**
 * @generated from enum announcement.v1.AnnouncementTag
 */
export enum AnnouncementTag {
  /**
   * @generated from enum value: ANNOUNCEMENT_TAG_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ANNOUNCEMENT_TAG_INFORMATION = 1;
   */
  INFORMATION = 1,

  /**
   * @generated from enum value: ANNOUNCEMENT_TAG_NOTIFICATION = 2;
   */
  NOTIFICATION = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AnnouncementTag)
proto3.util.setEnumType(AnnouncementTag, "announcement.v1.AnnouncementTag", [
  { no: 0, name: "ANNOUNCEMENT_TAG_UNSPECIFIED" },
  { no: 1, name: "ANNOUNCEMENT_TAG_INFORMATION" },
  { no: 2, name: "ANNOUNCEMENT_TAG_NOTIFICATION" },
]);

/**
 * If authenticated, is_read is present.
 *
 * @generated from message announcement.v1.Announcement
 */
export class Announcement extends Message<Announcement> {
  /**
   * @generated from field: shared.UUID id = 1;
   */
  id?: UUID;

  /**
   * @generated from field: repeated announcement.v1.AnnouncementTag tags = 2;
   */
  tags: AnnouncementTag[] = [];

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string content = 4;
   */
  content = "";

  /**
   * @generated from field: shared.RFC3339DateTime published_at = 5;
   */
  publishedAt?: RFC3339DateTime;

  /**
   * @generated from field: optional bool is_read = 6;
   */
  isRead?: boolean;

  constructor(data?: PartialMessage<Announcement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "announcement.v1.Announcement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: UUID },
    { no: 2, name: "tags", kind: "enum", T: proto3.getEnumType(AnnouncementTag), repeated: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "published_at", kind: "message", T: RFC3339DateTime },
    { no: 6, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Announcement {
    return new Announcement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Announcement {
    return new Announcement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Announcement {
    return new Announcement().fromJsonString(jsonString, options);
  }

  static equals(a: Announcement | PlainMessage<Announcement> | undefined, b: Announcement | PlainMessage<Announcement> | undefined): boolean {
    return proto3.util.equals(Announcement, a, b);
  }
}

