// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file announcement/v1/service.proto (package announcement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Announcement } from "./type_pb.js";
import { UUID } from "../../shared/type_pb.js";

/**
 * @generated from message announcement.v1.GetAnnouncementsRequest
 */
export class GetAnnouncementsRequest extends Message<GetAnnouncementsRequest> {
  constructor(data?: PartialMessage<GetAnnouncementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "announcement.v1.GetAnnouncementsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAnnouncementsRequest {
    return new GetAnnouncementsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAnnouncementsRequest {
    return new GetAnnouncementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAnnouncementsRequest {
    return new GetAnnouncementsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAnnouncementsRequest | PlainMessage<GetAnnouncementsRequest> | undefined, b: GetAnnouncementsRequest | PlainMessage<GetAnnouncementsRequest> | undefined): boolean {
    return proto3.util.equals(GetAnnouncementsRequest, a, b);
  }
}

/**
 * @generated from message announcement.v1.GetAnnouncementsResponse
 */
export class GetAnnouncementsResponse extends Message<GetAnnouncementsResponse> {
  /**
   * @generated from field: repeated announcement.v1.Announcement announcements = 1;
   */
  announcements: Announcement[] = [];

  constructor(data?: PartialMessage<GetAnnouncementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "announcement.v1.GetAnnouncementsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "announcements", kind: "message", T: Announcement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAnnouncementsResponse {
    return new GetAnnouncementsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAnnouncementsResponse {
    return new GetAnnouncementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAnnouncementsResponse {
    return new GetAnnouncementsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAnnouncementsResponse | PlainMessage<GetAnnouncementsResponse> | undefined, b: GetAnnouncementsResponse | PlainMessage<GetAnnouncementsResponse> | undefined): boolean {
    return proto3.util.equals(GetAnnouncementsResponse, a, b);
  }
}

/**
 * @generated from message announcement.v1.ReadAnnouncementsRequest
 */
export class ReadAnnouncementsRequest extends Message<ReadAnnouncementsRequest> {
  /**
   * @generated from field: repeated shared.UUID ids = 1;
   */
  ids: UUID[] = [];

  constructor(data?: PartialMessage<ReadAnnouncementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "announcement.v1.ReadAnnouncementsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "message", T: UUID, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReadAnnouncementsRequest {
    return new ReadAnnouncementsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReadAnnouncementsRequest {
    return new ReadAnnouncementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReadAnnouncementsRequest {
    return new ReadAnnouncementsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReadAnnouncementsRequest | PlainMessage<ReadAnnouncementsRequest> | undefined, b: ReadAnnouncementsRequest | PlainMessage<ReadAnnouncementsRequest> | undefined): boolean {
    return proto3.util.equals(ReadAnnouncementsRequest, a, b);
  }
}

/**
 * @generated from message announcement.v1.ReadAnnouncementsResponse
 */
export class ReadAnnouncementsResponse extends Message<ReadAnnouncementsResponse> {
  constructor(data?: PartialMessage<ReadAnnouncementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "announcement.v1.ReadAnnouncementsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReadAnnouncementsResponse {
    return new ReadAnnouncementsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReadAnnouncementsResponse {
    return new ReadAnnouncementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReadAnnouncementsResponse {
    return new ReadAnnouncementsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReadAnnouncementsResponse | PlainMessage<ReadAnnouncementsResponse> | undefined, b: ReadAnnouncementsResponse | PlainMessage<ReadAnnouncementsResponse> | undefined): boolean {
    return proto3.util.equals(ReadAnnouncementsResponse, a, b);
  }
}

